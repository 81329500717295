<template>
  <v-container class="py-4">
    <section class="d-flex flex-column flex-sm-row justify-content-between my-3">
      <h2 class="ml-1 my-3">
        Abastecimiento - Resumen
      </h2>
      <v-breadcrumbs :items="breadcrumb">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item active-class="active" :to="item.to" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </section>
    <v-card class="mb-5">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Punto de instalación</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row align-content="center">
        <v-col cols="12" md="4" sm="12">
          <v-card-subtitle class="pb-0">
            Código
          </v-card-subtitle>
          <v-card-text class="text--primary">
            <div>{{ $route.params.codigoLugarInstalacion }}</div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <br />
    <v-card class="px-2 py-3 mt-5 mx-2" elevation="2">
      <v-card-title>
        <h4>
          Tablero
        </h4>
      </v-card-title>
      <v-card-text>
        <board-items :showActions="false"></board-items>

        <board-attachments :showActions="false"></board-attachments>
      </v-card-text>
    </v-card>
    <materials-summary
      class="my-5 pt-4"
      title="Materiales en bodega"
      :materials="materialsInStore"
      :tableHeaders="headersMaterialsInStore"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsDocumentMaterial"
    ></materials-summary>
    <br />
    <materials-summary
      class="my-5 pt-4"
      title="Materiales sin código"
      :materials="materialsWithoutCode"
      :tableHeaders="headersMaterialsWithoutCode"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsDocumentMaterial"
    ></materials-summary>
    <br />
    <lists-execution :showAction="false"></lists-execution>
    <br />
    <materials-summary
      class="my-5 pt-4"
      title="Adicionales"
      :materials="[]"
      :tableHeaders="headersMaterialsWithoutCode"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsDocumentMaterial"
    ></materials-summary>

    <!-- Document Material Comments -->
    <document-material-comments
      :dialogShowCommentsDocumentMaterial="dialogShowCommentsDocumentMaterial"
      :closeModalShowCommentsDocumentMaterial="closeModalShowCommentsDocumentMaterial"
      :documentMaterialId="currentDocumentMaterial ? currentDocumentMaterial.id : null"
      :comments="comments"
      @commentAdded="
        getCubageMaterials({
          document_id: currentCubage.id
        })
      "
    ></document-material-comments>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { authMethods, authUsuarioComputed } from '@/state/helpers'
import MaterialsSummary from './components/MaterialsSummary.vue'
import { preventCloseIfNotKeyEventEsc } from '@/helpers/common'
import DocumentMaterialComments from '@/components/proyectos/lugarInstalacion/cubicles/components/DocumentMaterialComments.vue'
import BoardItems from '@/components/proyectos/lugarInstalacion/cubicles/mainTeams/BoardItems.vue'
import BoardAttachments from '@/components/proyectos/lugarInstalacion/cubicles/mainTeams/BoardAttachments.vue'
import ListsExecution from '@/components/proyectos/lugarInstalacion/cubicles/execution/index'
export default {
  components: {
    MaterialsSummary,
    DocumentMaterialComments,
    BoardItems,
    BoardAttachments,
    ListsExecution
  },
  async mounted() {
    await this.getCubageFromPlaceId({
      place_id: this.$route.params.codigoLugarInstalacion,
      user_id: this.user.email
    })
    this.getAllDispatchs()
  },
  data() {
    return {
      dialogShowCommentsDocumentMaterial: false,
      currentDocumentMaterial: null,
      comments: [],
      breadcrumb: [
        {
          text: 'Proyectos',
          to: { name: 'proyectos' }
        },
        {
          text: 'Abastecimiento - Resumen',
          to: { name: 'supplying-summary' }
        },
        {
          text: this.$route.params.codigoLugarInstalacion,
          disabled: true
        }
      ],
      headersMaterialsInStore: [
        {
          text: 'Estandar',
          align: 'center',
          sortable: true,
          value: 'material'
        },
        {
          text: 'Stock',
          align: 'center',
          sortable: true,
          value: 'material.stock'
        },
        {
          text: 'Código',
          align: 'center',
          sortable: true,
          value: 'material.code'
        },
        {
          text: 'Descripción',
          align: 'center',
          sortable: true,
          value: 'material.description'
        },
        {
          text: 'Unidad',
          align: 'center',
          sortable: true,
          value: 'material.measurement.units'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'quantity'
        },
        {
          text: 'CU',
          align: 'center',
          sortable: true,
          value: 'unit_cost'
        },
        {
          text: 'CT',
          align: 'center',
          sortable: true,
          value: 'total_cost'
        },
        {
          text: 'Despacho',
          align: 'center',
          sortable: false,
          value: 'dispatch.description'
        }
      ],
      headersMaterialsWithoutCode: [
        {
          text: 'Descripción',
          align: 'center',
          sortable: true,
          value: 'material.description'
        },
        {
          text: 'Unidad',
          align: 'center',
          sortable: true,
          value: 'material.measurement.units'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'quantity'
        },
        {
          text: 'CU',
          align: 'center',
          sortable: true,
          value: 'unit_cost'
        },
        {
          text: 'CT',
          align: 'center',
          sortable: true,
          value: 'total_cost'
        },
        {
          text: 'Despacho',
          align: 'center',
          sortable: false,
          value: 'dispatch.description'
        },
        { text: 'Acciones', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    ...authMethods,
    ...mapActions({
      getAllDispatchs: 'cubage/getAllDispatchs',
      getCubageFromPlaceId: 'cubage/getCubageFromPlaceId',
      getCubageMaterials: 'cubage/getCubageMaterials'
    }),
    openModalShowCommentsDocumentMaterial({ document }) {
      this.currentDocumentMaterial = document
      this.comments = document.comments
      this.dialogShowCommentsDocumentMaterial = true
    },
    closeModalShowCommentsDocumentMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event)
      if (validationEvent) {
        this.currentDocumentMaterial = null
        this.dialogShowCommentsDocumentMaterial = false
      }
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: 'cubage/currentCubage',
      cubageMaterials: 'cubage/cubageMaterials'
    }),
    materialsInStore() {
      return this.cubageMaterials.filter(({ material }) => material.code !== '-')
    },
    materialsWithoutCode() {
      return this.cubageMaterials.filter(({ material }) => material.code === '-')
    }
  },
  watch: {
    currentCubage: {
      immediate: true,
      handler: function(cubage) {
        if (cubage) {
          this.getCubageMaterials({
            document_id: cubage.id
          })
        }
      }
    }
  }
}
</script>

<style></style>
