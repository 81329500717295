<template>
  <v-data-table
    class="elevation-3 px-3"
    :headers="tableHeaders"
    :items="filteredMaterials"
    :items-per-page="5"
    :search="search"
    item-key="id"
    no-data-text="Sin materiales"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          <small class="font-weight-bold">
            {{ title }}
          </small>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-select
          :items="dispatchs"
          v-model="filters.dispatch"
          clearable
          class="w-25 pt-2 mx-3"
          label="Despacho"
          item-text="description"
          item-value="description"
        ></v-select>
        <v-text-field
          label="Busqueda"
          v-model="search"
          class="w-25 pt-2"
          flat
          placeholder="Buscar producto"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="d-flex align-items-center justify-content-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :color="item.comments.length ? 'primary' : 'secondary'"
              @click="showCommentsDocumentMaterial(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-alert-circle
              </v-icon>
            </v-btn>
          </template>
          <span>Información</span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:item.material="{ item }">
      <v-icon small color="yellow darken-2" v-if="item.material.favorite">
        mdi-star
      </v-icon>
      <span v-else>
        -
      </span>
    </template>
    <template v-slot:item.unit_cost="{ item }">
      <span>{{
        item.material && item.material.unit_cost
          ? `$ ${new Intl.NumberFormat('de-DE').format(parseInt(item.material.unit_cost))}`
          : `$ ${0}`
      }}</span>
    </template>
    <template v-slot:item.total_cost="{ item }">
      <span>{{
        item.material && item.material.unit_cost && item.quantity
          ? `$ ${new Intl.NumberFormat('de-DE').format(parseInt(item.total_cost))}`
          : `$ ${0}`
      }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    tableHeaders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: '',
      filters: {
        dispatch: null
      }
    }
  },
  methods: {
    showCommentsDocumentMaterial(item) {
      this.$emit('openModalShowCommentsDocumentMaterial', { document: item })
    }
  },
  computed: {
    ...mapGetters({
      dispatchs: 'cubage/dispatchs'
    }),
    filteredMaterials() {
      let self = this
      return this.materials.filter(function(material) {
        return self.filters.dispatch
          ? material.dispatch.description === self.filters.dispatch
          : material
      })
    }
  }
}
</script>

<style></style>
