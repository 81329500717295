var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3 px-3",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredMaterials,"items-per-page":5,"search":_vm.search,"item-key":"id","no-data-text":"Sin materiales"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-select',{staticClass:"w-25 pt-2 mx-3",attrs:{"items":_vm.dispatchs,"clearable":"","label":"Despacho","item-text":"description","item-value":"description"},model:{value:(_vm.filters.dispatch),callback:function ($$v) {_vm.$set(_vm.filters, "dispatch", $$v)},expression:"filters.dispatch"}}),_c('v-text-field',{staticClass:"w-25 pt-2",attrs:{"label":"Busqueda","flat":"","placeholder":"Buscar producto","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.comments.length ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.showCommentsDocumentMaterial(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Información")])])],1)]}},{key:"item.material",fn:function(ref){
var item = ref.item;
return [(item.material.favorite)?_c('v-icon',{attrs:{"small":"","color":"yellow darken-2"}},[_vm._v(" mdi-star ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.unit_cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.material && item.material.unit_cost ? ("$ " + (new Intl.NumberFormat('de-DE').format(parseInt(item.material.unit_cost)))) : ("$ " + (0))))])]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.material && item.material.unit_cost && item.quantity ? ("$ " + (new Intl.NumberFormat('de-DE').format(parseInt(item.total_cost)))) : ("$ " + (0))))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }